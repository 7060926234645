// import { getStrapiImage } from "@/lib/api";
import { getStrapiImagePlaceholder } from '../../lib/api'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import React from 'react'
import Container from '@mui/material/Container'
import { getStrapiImage } from '@/lib/api'

const Winner: React.FC<Props<Gagnants>> = ({ data }) => {
  return (
    <Container>
      <Stack spacing={2} sx={{ px: { xs: 0, md: 12, lg: 20 }, my: 4 }}>
        <Stack alignItems="center" sx={{ my: 4 }}>
          <Typography
            variant="h1"
            textAlign="center"
            noWrap
            fontWeight={800}
            sx={{ width: 1 }}
            color="accent.main"
          >
            {data?.titre}
          </Typography>
          <Typography variant="h4" fontWeight={700} sx={{ mt: '-70px' }}>
            {data?.sub}
          </Typography>
        </Stack>
        <Typography color="text.secondary" textAlign="center" gutterBottom>
          {data?.description}
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        flexWrap="nowrap"
        spacing={4}
        sx={{ pt: 4 }}
      >
        {data?.liste?.map((el, i) => (
          <Stack
            key={i}
            sx={{
              width: 1,
              height: 450,
              bgcolor: 'primary.main',
              position: 'relative',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Image
              alt="Updev Challenge"
              src={getStrapiImage(el?.media)}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              placeholder="blur"
              blurDataURL={getStrapiImagePlaceholder(el?.media)}
              priority
            ></Image>
            <Stack
              justifyContent="flex-end"
              sx={{
                position: 'relative',
                height: 1,
                p: 3,
                background: 'linear-gradient(#00000005, 55%, #050506f0)',
              }}
            >
              <Typography variant="h6" component="h1" color="secondary.main">
                {el?.place}
              </Typography>
              <Typography variant="h5" component="h1" color="#fff" gutterBottom>
                {el?.nom}
              </Typography>
              <Typography color="#d8d8d8" gutterBottom>
                {el?.description}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Container>
  )
}

export default Winner
